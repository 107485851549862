import { Component, ElementRef, EventEmitter, Input, OnInit, TemplateRef, ViewChild,Output } from '@angular/core';
import { PaymentService } from 'src/app/services/Operations/paymentService.service';
import { VendorService } from 'src/app/services/Operations/Vendor.service';
import { ToastrService } from 'ngx-toastr';
import { EEdgeExpressECIType,EOpenEdgeResponseCode,EPaymentModes,EEdgeExpressResultCode,EPaymentGateway,EPaymentTransactionType,EOpenEdgeSessionStates,ECreditCards} from '../../enum';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedDataService } from '../../SharedData.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  credentials;
  paymentGateway;
  paymentStarted=false;
  public loaderIcon: Promise<boolean>;
  paymentFailReason;
  isSaveInCCTResponse;
  paymentInProgress = false;
  isAutoPopulateBillingInfo =false;
  paymentTable = [];
  currentPayId ;
  hstPayBtnMsg;
  payModalRef: BsModalRef;
  transactionInitiated;
  tempCNPRes: any;
  paymentProcessing = false;
  vendorInfo;
  showIFrame = false;
  facilityCreditCards: string = '';
  selectedDeviceId = 0;
  cctStatusOrderId = 0;
  voidOnCancelInitiated = false;
  @Output() isCancel: EventEmitter<any> = new EventEmitter<any>();
  sessionId;
  showPage = true;
  requestParam: String;
  tempCNPPayId = null;
  tempCNPAmount = null;
  retryTransactionStatusLoader = false;
  private paymentMaxWaitTime = environment.paymentMaxWaitTime;
  private paymentGetCCTStatusInterval = environment.paymentGetCCTStatusInterval;
  urlSafe: SafeResourceUrl;
  achUrlSafe: SafeResourceUrl;
  urlSafeAlias: SafeResourceUrl;
  paymentSuccessFul: boolean;
  @Input() keyedInTransaction;
  @Input() TransactionType;
  payStatus;
  @Input() VendorId;
  @Input() CCAmount;
  @Input() isQDR;
  @Output() PaymentResponse = new EventEmitter<any>();
  @Output() ClosePaymentPage = new EventEmitter<any>();
  paymentCount;
  tillNowPaid;
  multiPaymentVoidEmv: any;
  openEdgeSub: any;
  tenderAmount = 0;
  changeAmount;
  @Input() voidPayment;
  @Input() ForceCreditReturn;
  @Input() VerifoneId;
  @Input() RefundAmount ;

  tempRcmRes = {
    rcmSessionId: '',
    rcmResponse: ''
  }
  @ViewChild('CPNIFrame', { static: false }) iframe: ElementRef;
  @ViewChild('paymentSuccess', { static: true }) popUpPayment: TemplateRef<any>;
  openEdgeBillingFields='';
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  maxLengthNameOnCard = 30;
  maxLengthCCBlillingAd = 50;
  maxLengthCCBlillingZip = 15;
  initiatedVoidCC = false;
  showLoaderMesage = false;
  verifoneRecordId = 0;
  @Input() IsMR = false;
  MASKEDCARDNUMBER = ''
  constructor(private paymentService: PaymentService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private vendorService:VendorService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private sharedDataService: SharedDataService,) {
  }

  ngOnInit() {
    this.paymentCount = 0;
    this.paymentTable = [];
    this.paymentCount++;
    const obj = {
      payId: this.paymentCount,
      payName: 'Payment ' + this.paymentCount,
      payAmount: this.CCAmount,
      payComplete: false,
      payNowDisable: true,
      payModeId: 0,
      orderId: null,
      changeAmount: 0,
      checkNum: 0,
      checkDate: '',
      bankName: '',
      ManualCardNumber: '',
      ManualCardExp: '',
      ManualCardName: '',
      CreditCardType: 0,
      ManualCardAuthorization: '',
      defaultmode: 0,
      uniqueTransId: null,
      payFailed: false,
      routingNumber: 0,
      accountNumber: 0,
      achManualCardAuthorization: '',
      accountType: 279,
      nameonAccount: ''
    };

    this.paymentTable.push(obj);
    
    this.getVendorInfo();
    this.getCredentials();
  }

  getVendorInfo() {
    this.vendorService.GetVendorById(this.VendorId).subscribe((res) => {
      if (res !== null) {
        this.vendorInfo = res.body;
      }
    })
  }

  getCredentials() {
    
    this.paymentService.getCCCredentials().subscribe((res) => {
      if (res !== null) {
   
        this.paymentGateway = res.PaymentGateWay;
        this.credentials = res.GatewayDetails ? JSON.parse(res.GatewayDetails) : null;
        if (this.voidPayment == true) {
         this.voidPaymentTransaction(this.VerifoneId,this.credentials);
        }
        else {
          this.setOpenEdgeMandatory();
          this.initiatePaymentRequest();
        }
      }
    })
  }

  generateIntialCCT(amt, transTypeId, ptId, paymentModeId, payvoid, cardNumber, authCode, voidOrderId) {
    let CreditCardNumber = cardNumber ? cardNumber.substr(cardNumber.length - 4) : "";
    const CCTModel = {
      verifoneRecordId: this.verifoneRecordId,
      Amount: amt,
      VendorId: this.VendorId,
      CreditCardNumber: CreditCardNumber,
      CreditCardType: ECreditCards.Visa,     
      ResponseCode: "",
      ResponseMessage: "",     
      OrderId: voidOrderId ? voidOrderId : "",
      TransactionDateTime: new Date(),
      ProcessStatus: "In progress"
    };
    return Object.assign(CCTModel);
  }

  isAnyPaymentProcessed() {
    let status = this.paymentTable.some(function (ele) { return ele.payComplete });
    if (!status) {
      this.paymentInProgress = false;
    }
  }

  callRCM(amount, payId, orderId) {
    let tempAmount;
    //const TempURL = 'https://localsystem.paygateway.com:21113/RcmService.svc/Initialize?xl2Parameters=';
    this.paymentTable.find(o => o.payId == payId).uniqueTransId = orderId;
    if (!this.credentials.RCMURL) {
      this.toastr.warning('Please ask Administrator to configure RCM URL', 'URL required', this.toasterConfig);
      this.loaderIcon = Promise.resolve(false);
    } else {
      this.paymentMaxWaitTime = this.paymentMaxWaitTime != undefined && this.paymentGetCCTStatusInterval != 0 ? this.paymentMaxWaitTime : 2;
      this.paymentGetCCTStatusInterval = this.paymentGetCCTStatusInterval != undefined && this.paymentGetCCTStatusInterval != 0 ? this.paymentGetCCTStatusInterval : 10;
      let isPaymentCompleted = false; //for retry logic
      this.paymentInProgress = true;
      let emvTransactionType = this.TransactionType
      
      const requestParam = "<REQUEST>" +
        "<XWEBID>" + this.credentials.XWEBID + "</XWEBID>" +
        "<XWEBTERMINALID>" + this.credentials.XWEBTERMINALID + "</XWEBTERMINALID>" +
        "<XWEBAUTHKEY>" + this.credentials.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
        "<TRANSACTIONTYPE>" + emvTransactionType + "</TRANSACTIONTYPE>  <AMOUNT>" + amount + "</AMOUNT> <ORDERID>" + orderId + "</ORDERID> <OTK>" + orderId + "</OTK><ALLOWDUPLICATES>True</ALLOWDUPLICATES><CLERK></CLERK> <RECEIPTCOPYLABEL>MERCHANT_COPY</RECEIPTCOPYLABEL><TITLE>BTCloud</TITLE><CREATEALIAS>FALSE</CREATEALIAS></REQUEST>" //JSON.stringify(tempObj);
      const rcmRequest = this.paymentService.getRCMService(this.credentials.RCMURL, requestParam).subscribe((res: any) => {
        if (res) {
          const resultFromRCM = res;
          isPaymentCompleted = true;
          const responseData = JSON.parse(resultFromRCM.RcmResponse);
         // this.prePayStatus = 'Payment';
          if ((responseData && responseData.RESPONSE && responseData.RESPONSE.APPROVEDAMOUNT != 0 && responseData.RESPONSE.HOSTRESPONSECODE && responseData.RESPONSE.RESULT && responseData.RESPONSE.CARDBRAND && responseData.RESPONSE.ACCOUNT
            && responseData.RESPONSE.EXPMONTH && responseData.RESPONSE.EXPYEAR && responseData.RESPONSE.TRANSACTIONID && responseData.RESPONSE.HOSTRESPONSEDESCRIPTION) || (responseData.RESPONSE.RESULT == EEdgeExpressResultCode.Canceled || responseData.RESPONSE.RESULT == EEdgeExpressResultCode.Declined || responseData.RESPONSE.RESULT == EEdgeExpressResultCode.Fail)) {
            let cTypeId = responseData.RESPONSE && responseData.RESPONSE.CARDBRAND ? this.paymentService.openEdgeCardType(responseData.RESPONSE.CARDBRAND) : ECreditCards.Visa;
            if (responseData && responseData.RESPONSE && responseData.RESPONSE.RESULT == EEdgeExpressResultCode.Success) {//Success
              this.payStatus = 'Success';
              this.paymentTable.find(o => o.payId == payId).payFailed = false;
             // this.disableNextButton();
              this.paymentSuccessFul = true;
              tempAmount = amount;
              responseData.RESPONSE['OrderId'] = responseData.RESPONSE && responseData.RESPONSE.ORDERID ? responseData.RESPONSE.ORDERID : orderId;
              this.paymentTable.find(o => o.payId == payId)['processedCC'] = responseData.RESPONSE;
              let CCTModel;
              const receiptText = responseData.RESPONSE.RECEIPTTEXT;
              const entryMethodMatch = receiptText.match(/Entry Method\s+([^\n]+)/);
              const entryLegendMatch = receiptText.match(/Entry Legend\s+([^\n]+)/);
              const ACMatch = receiptText.match(/AC\s+([^\n]+)/);
              const ATCMatch = receiptText.match(/ATC\s+([^\n]+)/);
              const AIDMatch = receiptText.match(/AID\s+([^\n]+)/);
              const AIDNAMEMatch = receiptText.match(/AID NAME\s+([^\n]+)/);
              const TVRMatch = receiptText.match(/TVR\s+([^\n]+)/);
              const TSIMatch = receiptText.match(/TSI\s+([^\n]+)/);
              const RespCDMatch = receiptText.match(/Resp CD\s+([^\n]+)/);
              const TRNREFNoMatch = receiptText.match(/TRN REF #\s+([^\n]+)/);
              const VALCODEMatch = receiptText.match(/VAL CODE\s+([^\n]+)/);
              
              const tempCCModel = {
                VerifoneId: this.verifoneRecordId,
                Amount: parseFloat(amount),
                CreditCardNumber: responseData.RESPONSE.ACCOUNT,
                CardType: responseData.RESPONSE.CARDTYPE,
                ResponseCode: responseData.RESPONSE.HOSTRESPONSECODE,
                ResponseMessage: responseData.RESPONSE.HOSTRESPONSEDESCRIPTION,
                Card: this.TransactionType == "CREDITSALE" ? "Credit Card" : "Debit Card",
                TransactionDateTime: new Date(),
                TransactionTypeId: "",
                OrderId: responseData.RESPONSE.ORDERID,
                VendorId: this.VendorId,
                ProcessStatus: "Completed",
                ApprovalCode: responseData.RESPONSE.APPROVALCODE,
                PaymentTransactionTypeId: responseData.RESPONSE.TRANSACTIONID,
                PaymentTransactionType: "Payment Purchase",
                CreditCardType: responseData.RESPONSE.CARDBRAND,
                ReceiptResponse: responseData.RESPONSE.RECEIPTTEXT,
                EntryLegend: entryLegendMatch ? entryLegendMatch[1].trim() : '',
                EntryMethod: entryMethodMatch ? entryMethodMatch[1].trim() : '',
                AC: ACMatch ? ACMatch[1].trim() : '',
                ATC: ATCMatch ? ATCMatch[1].trim() : '',
                AID: AIDMatch ? AIDMatch[1].trim() : '',
                AIDNAME: AIDNAMEMatch ? AIDNAMEMatch[1].trim() : '',
                TVR: TVRMatch ? TVRMatch[1].trim() : '',
                TSI: TSIMatch ? TSIMatch[1].trim() : '',
                RespCD: RespCDMatch ? RespCDMatch[1].trim() : '',
                TRNREFNo: TRNREFNoMatch ? TRNREFNoMatch[1].trim() : '',
                VALCODE: VALCODEMatch ? VALCODEMatch[1].trim() : ''
              };

              CCTModel = Object.assign(tempCCModel);
              this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
              });
              this.loaderIcon = Promise.resolve(false);
              this.MASKEDCARDNUMBER =  responseData.RESPONSE.ACCOUNT
              this.gotoPaymentSuccess(this.popUpPayment, tempAmount);
            } else if (responseData && responseData.RESPONSE && responseData.RESPONSE.RESULT == EEdgeExpressResultCode.Canceled || responseData.RESPONSE.RESULT == EEdgeExpressResultCode.Fail) { //Cancel
              this.payStatus = 'Cancelled';
              this.paymentTable.find(o => o.payId == payId).payFailed = true;
              this.isAnyPaymentProcessed();
              this.paymentSuccessFul = false;
              this.paymentProcessing = false;
              this.paymentFailReason = responseData.RESPONSE.RESULTMSG;
              tempAmount = 0;
              let CCTModel;
              const tempCCModel = {
                Amount: parseFloat(amount),      
                CreditCardNumber: responseData.RESPONSE && responseData.RESPONSE.ACCOUNT ? responseData.RESPONSE.ACCOUNT : "0",              
                ResponseCode: responseData.RESPONSE && responseData.RESPONSE.HOSTRESPONSECODE ? responseData.RESPONSE.HOSTRESPONSECODE : responseData.RESPONSE.RESULT,
                ResponseMessage: responseData.RESPONSE && responseData.RESPONSE.HOSTRESPONSEDESCRIPTION ? responseData.RESPONSE.HOSTRESPONSEDESCRIPTION : responseData.RESPONSE.RESULTMSG,
                TransactionDateTime: new Date(),
                OrderId: this.paymentTable.find(o => o.payId == this.currentPayId).uniqueTransId,
                VendorId: this.VendorId,
                VerifoneId: this.verifoneRecordId,
                ProcessStatus: "Failed",
                Card: this.TransactionType == "CREDITSALE" ? "Credit Card" : "Debit Card",
              };

              CCTModel = Object.assign(tempCCModel);
              this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
              });         
              this.MASKEDCARDNUMBER = responseData.RESPONSE.ACCOUNT ? responseData.RESPONSE.ACCOUNT : "xxxx"
              this.gotoPaymentSuccess(this.popUpPayment, tempAmount);
              this.loaderIcon = Promise.resolve(false);
            } else {
              this.isAnyPaymentProcessed();
              this.payStatus = 'Failed';
              this.paymentTable.find(o => o.payId == payId).payFailed = true;
              tempAmount = 0;
              this.paymentSuccessFul = false;
              this.paymentFailReason = responseData.RESPONSE && responseData.RESPONSE.RESULTMSG ? responseData.RESPONSE.RESULTMSG : 'Declined!';
              this.paymentProcessing = false;
              const CCTModel = {
                Amount: parseFloat(amount),      
                CreditCardNumber: responseData.RESPONSE && responseData.RESPONSE.ACCOUNT ? responseData.RESPONSE.ACCOUNT : "0",              
                ResponseCode: responseData.RESPONSE && responseData.RESPONSE.HOSTRESPONSECODE ? responseData.RESPONSE.HOSTRESPONSECODE : responseData.RESPONSE.RESULT,
                ResponseMessage: responseData.RESPONSE && responseData.RESPONSE.HOSTRESPONSEDESCRIPTION ? responseData.RESPONSE.HOSTRESPONSEDESCRIPTION : responseData.RESPONSE.RESULTMSG,
                TransactionDateTime: new Date(),
                OrderId: this.paymentTable.find(o => o.payId == this.currentPayId).uniqueTransId,
                VendorId: this.VendorId,
                VerifoneId: this.verifoneRecordId,
                ProcessStatus: "Failed",
                Card: this.TransactionType == "CREDITSALE" ? "Credit Card" : "Debit Card",
              };
              this.paymentService.saveVerifoneUnSaved(Object.assign(CCTModel)).subscribe((res: any) => { });
              this.loaderIcon = Promise.resolve(false);
              this.MASKEDCARDNUMBER = responseData.RESPONSE.ACCOUNT ? responseData.RESPONSE.ACCOUNT : "xxxx"
              this.gotoPaymentSuccess(this.popUpPayment, tempAmount);
            }
          } else {
            rcmRequest.unsubscribe();
            tempAmount = 0;
            this.paymentSuccessFul = false;
            this.MASKEDCARDNUMBER = responseData.RESPONSE.ACCOUNT ? responseData.RESPONSE.ACCOUNT : "xxxx"
            this.gotoPaymentSuccess(this.popUpPayment, tempAmount);
            this.loaderIcon = Promise.resolve(false);
          }
        }
      }, err => {
        isPaymentCompleted = true;
        this.paymentTable.find(o => o.payId == payId).payFailed = true;
        this.loaderIcon = Promise.resolve(false);
        this.toastr.warning('Please connect the device ', 'Device Required', this.toasterConfig);
        this.payStatus = 'Failed';
        this.paymentFailReason = 'Device Not Connected!'
        this.isAnyPaymentProcessed();
        this.paymentSuccessFul = false;
        this.paymentProcessing = false;
        const CCTModel = {
          Amount: parseFloat(amount),
          CreditCardNumber: 0,
          CreditCardType: ECreditCards.Visa,
          ResponseCode: '0',
          ResponseMessage: "Device not Connected!",
          PaymentTransactionTypeId: 1,
          TransactionDateTime: new Date(),
          TransactionTypeId: EPaymentTransactionType.Declined,
          OrderId: this.paymentTable.find(o => o.payId == this.currentPayId).orderId,
          VendorId:this.VendorId
        };
        this.paymentService.saveVerifoneUnSaved(Object.assign(CCTModel)).subscribe((res: any) => {
        });
        this.MASKEDCARDNUMBER = "xxxx"
        this.gotoPaymentSuccess(this.popUpPayment, tempAmount);
      });
      setTimeout(() => {
        if (!isPaymentCompleted) {
          this.cctStatusOrderId = 0;
          rcmRequest.unsubscribe();
          this.openEdgeTransactionStatus(orderId, payId, 'Sale');
        }
      }, 10000 * 6 * this.paymentMaxWaitTime);
    }
  }

  openEdgeTransactionStatus(OrderId, payId, transactionType) { //calling from Original Request
    // this.paymentService.GetCreditCardTransactionById(OrderId).subscribe((cctResponse: any) => {
    //   let res = JSON.parse(cctResponse);
    //   if (res.Reason == "Attempting to Process") {
    //     this.initiatingOpenEdgeQueryPaymentRequest(OrderId, transactionType, 1, payId, true)
    //   } else {
    //     this.paymentFailReason = "Please Contact Administrator to check in Gateway Portal if Payment is Approved. If Yes, key in the Payment as Manual Authorization";
    //     this.saveCCTransactionFailureCase(this.tempRcmRes.rcmResponse, this.paymentFailReason, transactionType, 1, OrderId);
    //   }
    // }, err => {
    //   this.loaderIcon = Promise.resolve(false);
    // });
  }

  initiatingOpenEdgeQueryPaymentRequest(orderId, transactionType, paymentTrnsId, payId, callRetryLogic) {
    if (this.credentials) {
      this.paymentService.getCNPResponse(this.credentials, orderId).subscribe((res: any) => {
        const jsonResp = JSON.parse(res);
     //   this.showLoaderMesage = false;
        this.tempRcmRes.rcmResponse = jsonResp;
        let amount;
        if (this.cctStatusOrderId == 0) {//To avoid Duplicate Save APi call
          if (jsonResp.RESULT && jsonResp.RESULT.RESPONSECODE == EOpenEdgeResponseCode.Approval && jsonResp.RESULT.STATE != 'IN_PROGRESS') {
            this.cctStatusOrderId = jsonResp.RESULT.ORDERID;
            if (this.openEdgeSub) {
              this.openEdgeSub.unsubscribe();
              this.openEdgeSub = null;
            }
          //  this.retryTransactionStatusLoader = false;
            if (transactionType == 'Void') {
              const CCModel = {
                Amount: jsonResp.RESULT.APPROVEDAMOUNT,
                CreditCardNumber: jsonResp.RESULT.MASKEDCARDNUMBER == undefined ? 0 : jsonResp.RESULT.MASKEDCARDNUMBER,
                CreditCardType: jsonResp.RESULT && jsonResp.RESULT.CARDBRAND ? this.paymentService.openEdgeCardType(jsonResp.RESULT.CARDBRAND) : ECreditCards.Visa,
                 ResponseCode: jsonResp.RESULT.RESPONSECODE,
                 ResponseMessage: jsonResp.RESULT.RESPONSEDESCRIPTION,              
                PaymentTransactionTypeId: paymentTrnsId,
                OrderId: orderId,
                 TransactionDateTime: new Date(),
                TransactionTypeId: EPaymentTransactionType.Reversal_Refund, //Reversal
                VendorId : this.VendorId,
              };
              this.paymentService.saveVerifoneUnSaved(Object.assign(CCModel)).subscribe((res: any) => { });
              //this.retryTransactionStatusLoader = false;
              // this.multiPaymentVoidEmv.find(t => t.orderId === (this.paymentTable.find(o => o.payId == this.currentPayId).orderId)).isEmvVoidInitiated = true;
              // if (this.multiPaymentVoidEmv.some(function (ele) { return (ele.isEmvVoidInitiated == false); })) {
              //   this.callOpenEdgeVoid(paymentTrnsId);
              // } else {
                if (!this.voidOnCancelInitiated) {
                  this.isCancel.emit(true);
                }
                this.loaderIcon = Promise.resolve(false);
              //}
            } else {
              this.payStatus = 'Success';
              this.paymentSuccessFul = true;
              this.paymentTable.find(o => o.payId == payId).payFailed = false;
              amount = parseFloat(jsonResp.RESULT.APPROVEDAMOUNT).toFixed(2);
              jsonResp.RESULT['OrderId'] = jsonResp.RESULT && jsonResp.RESULT.ORDERID ? jsonResp.RESULT.ORDERID : orderId;
              jsonResp.RESULT['CARDHOLDERNAME'] = jsonResp.RESULT.CARDBRAND;
              jsonResp.RESULT['ACCOUNT'] = jsonResp.RESULT.MASKEDCARDNUMBER;
              jsonResp.RESULT['HOSTRESPONSECODE'] = jsonResp.RESULT.RESPONSECODE;
              jsonResp.RESULT['HOSTRESPONSEDESCRIPTION'] = jsonResp.RESULT.ORIGINALRESPONSEDESCRIPTION;
              this.paymentTable.find(o => o.payId == payId)['processedCC'] = jsonResp.RESULT;
              this.loaderIcon = Promise.resolve(false);
              this.MASKEDCARDNUMBER = jsonResp.RESULT.MASKEDCARDNUMBER
              this.gotoPaymentSuccess(this.popUpPayment, amount);
            }
            this.getRCMStatus(orderId, true);
          } else if (jsonResp.RESULT && jsonResp.RESULT.STATE != 'IN_PROGRESS' && (jsonResp.RESULT.RESPONSECODE != EOpenEdgeResponseCode.TransactionDoesNotExist && jsonResp.RESULT.RESPONSEDESCRIPTION != 'Invalid Reference Error: Transaction does not exist')) {
           // this.retryTransactionStatusLoader = false;
            let reason = ((jsonResp.RESULT.RESPONSECODE != EOpenEdgeResponseCode.TransactionDoesNotExist && jsonResp.RESULT.RESPONSEDESCRIPTION != 'Invalid Reference Error: Transaction does not exist') || jsonResp.RESULT.ORDERID == orderId) ? jsonResp.RESULT.RESPONSEDESCRIPTION : "Transaction Cancelled";
            if (this.openEdgeSub) {
              this.openEdgeSub.unsubscribe();
              this.openEdgeSub = null;
            }
            this.paymentFailReason = reason;
            this.saveCCTransactionFailureCase(jsonResp, reason, transactionType, paymentTrnsId, orderId);
            this.getRCMStatus(orderId, false);
            this.loaderIcon = Promise.resolve(false);
          } else if (callRetryLogic) {
            // this.paymentService.GetCreditCardTransactionById(orderId).subscribe((cctResponse: any) => {
            //   let res = JSON.parse(cctResponse);
            //   if (!this.openEdgeSub && res.Reason == "Attempting to Process") {
            //     this.cctStatusOrderId = 0;
            //     this.retryTransactionStatusLoader = true;
            //     let interval = this.paymentGetCCTStatusInterval * 1000;
            //     const source = Observable.interval(interval);
            //     this.openEdgeSub = source.subscribe(val => this.getOpenEdgeTransactionStatus(orderId, payId, val, transactionType, paymentTrnsId));
            //   } else {
            //     this.paymentTable.find(o => o.payId == payId).payFailed = true;
            //     this.paymentFailReason = "Please Contact Administrator to check in Gateway Portal if Payment is Approved. If Yes, key in the Payment as Manual Authorization";
            //     this.saveCCTransactionFailureCase(jsonResp, this.paymentFailReason, transactionType, paymentTrnsId, orderId);
            //   }
            // });
          } else {
            this.paymentService.getRCMServiceStatus(null, orderId, false).subscribe((sessionRes: any) => {
              if (sessionRes) {
                this.tempRcmRes.rcmSessionId = sessionRes.SessionId;
                if (sessionRes.SessionState == EOpenEdgeSessionStates.Completed && jsonResp.RESULT && jsonResp.RESULT.RESPONSECODE == EOpenEdgeResponseCode.TransactionDoesNotExist && jsonResp.RESULT.RESPONSEDESCRIPTION == 'Invalid Reference Error: Transaction does not exist') {
                  //this.retryTransactionStatusLoader = false;
                  if (this.openEdgeSub) {
                    this.openEdgeSub.unsubscribe();
                    this.openEdgeSub = null;
                  }
                  this.paymentFailReason = "Declined!";
                  jsonResp.RESULT.RESPONSEDESCRIPTION = jsonResp.RESULT.RESPONSEDESCRIPTION + " : RCM Session Completed!";
                  this.paymentTable.find(o => o.payId == payId).payFailed = true;
                  this.saveCCTransactionFailureCase(jsonResp, this.paymentFailReason, transactionType, paymentTrnsId, orderId);
                }
              }
            });
          }
        }
      }, err => {
        this.paymentTable.find(o => o.payId === payId).payFailed = true;
       // this.prePayStatus = 'Payment';
        this.payStatus = 'Failed!';
        this.paymentFailReason = "Payment Status not received. Please Contact Administrator to check in Gateway Portal if Payment is Approved. If Yes, key in the Payment as Manual Authorization";
        this.loaderIcon = Promise.resolve(false);
        if (this.openEdgeSub) {
          this.openEdgeSub.unsubscribe();
          this.openEdgeSub = null;
        }
        this.saveCCTransactionFailureCase(null, this.paymentFailReason, transactionType, paymentTrnsId, orderId);
      });
    } else {
      this.toastr.warning('Please ask Administrator to configure RCM URL', 'URL required', this.toasterConfig);
      this.loaderIcon = Promise.resolve(false);
    }
  }

  saveCCTransactionFailureCase(jsonResp, paymentFailReason, transactionType, paymentTrnsId, orderId) {
    this.paymentSuccessFul = false;
    this.paymentProcessing = false;
    this.payStatus = 'Failed';
    this.isAnyPaymentProcessed();
    this.paymentFailReason = paymentFailReason ? paymentFailReason : jsonResp.RESULT.STATE;
    const CCModel = {
      Amount: this.CCAmount,
       CreditCardNumber: jsonResp && jsonResp.RESULT && jsonResp.RESULT.MASKEDCARDNUMBER ? jsonResp.RESULT.MASKEDCARDNUMBER : '0',
      CreditCardType: jsonResp && jsonResp.RESULT && jsonResp.RESULT.CARDBRAND ? this.paymentService.openEdgeCardType(jsonResp.RESULT.CARDBRAND) : ECreditCards.Visa,
     ResponseCode: jsonResp && jsonResp.RESULT && jsonResp.RESULT.RESPONSECODE ? jsonResp.RESULT.RESPONSECODE : '0',
     ResponseMessage: (jsonResp.RESULT && jsonResp.RESULT && jsonResp.RESULT.STATE == 'IN_PROGRESS') ? jsonResp.RESULT.STATE : (jsonResp && jsonResp.RESULT && jsonResp.RESULT.RESPONSEDESCRIPTION) ? jsonResp.RESULT.RESPONSEDESCRIPTION : this.paymentFailReason,
     PaymentTransactionTypeId: paymentTrnsId,
      OrderId: orderId,
      TransactionDateTime: new Date(),
      TransactionTypeId: EPaymentTransactionType.Declined,
      VendorId: this.VendorId
     };
    this.paymentService.saveVerifoneUnSaved(Object.assign(CCModel)).subscribe((res: any) => { });
    if (transactionType == 'Void') {
      this.multiPaymentVoidEmv.find(t => t.orderId === (this.paymentTable.find(o => o.payId == this.currentPayId).orderId)).isEmvVoidInitiated = true;
      if (this.multiPaymentVoidEmv.some(function (ele) { return (ele.isEmvVoidInitiated == false); })) {
        this.callOpenEdgeVoid(paymentTrnsId);
      } else {
        if (!this.voidOnCancelInitiated) {
          this.isCancel.emit(true);
        }
        this.loaderIcon = Promise.resolve(false);
      }
      this.toastr.error(`Failed to Reverse the Payment! - $${this.CCAmount}`, 'Failure', this.toasterConfig);
    } else {
      this.toastr.error('Payment Failed', 'Failure');
      this.gotoPaymentSuccess(this.popUpPayment, 0);
      this.loaderIcon = Promise.resolve(false);
    }
  }

  callOpenEdgeVoid(paymentTrnsId) {
    var initiatingVoid = this.multiPaymentVoidEmv.find(t => t.isEmvVoidInitiated == false);
    if (initiatingVoid) {
      let CCTModel = this.generateIntialCCT(initiatingVoid['processedCC'].APPROVEDAMOUNT, EPaymentTransactionType.Reversal_Refund, paymentTrnsId, initiatingVoid.payModeId, null, null, null, null);
      this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
        if (res.Success) {
          let orderId = res.Id;
       //   this.showLoaderMesage = true;
          let isVoidCompleted = false;
          const requestParam = "<REQUEST> " + "<XWEBID>" + this.credentials.XWEBID + "</XWEBID>" +
            "<XWEBTERMINALID>" + this.credentials.XWEBTERMINALID + "</XWEBTERMINALID>" +
            "<XWEBAUTHKEY>" + this.credentials.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
            "<TRANSACTIONTYPE>DEBITRETURN</TRANSACTIONTYPE><AMOUNT>" + initiatingVoid['processedCC'].APPROVEDAMOUNT + "</AMOUNT><ORDERID>" + orderId + "</ORDERID><OTK>" + orderId + "</OTK></REQUEST>";
          this.initiatedVoidCC = true;
          const rcmRequest = this.paymentService.getRCMService(this.credentials.RCMURL, requestParam).subscribe((res: any) => {
            if (res) {
              isVoidCompleted = true;
              const resultFromRCM = res;
              this.multiPaymentVoidEmv.find(t => t.orderId === initiatingVoid.orderId).isEmvVoidInitiated = true;
              const responseData = JSON.parse(resultFromRCM.RcmResponse);
              let cTypeId = responseData && responseData.RESPONSE && responseData.RESPONSE.CARDBRAND ? this.paymentService.openEdgeCardType(responseData.RESPONSE.CARDBRAND) : ECreditCards.Visa;
              if (responseData.RESPONSE.RESULT == 0) { //Success
                let CCTModel;
                const tempCCModel = {
                  Amount: responseData.RESPONSE.APPROVEDAMOUNT,
                 CreditCardNumber: responseData.RESPONSE.ACCOUNT == undefined ? 0 : responseData.RESPONSE.ACCOUNT,
                  CreditCardType: cTypeId,
                  ResponseCode: responseData.RESPONSE.HOSTRESPONSECODE,
                  ResponseMessage: responseData.RESPONSE.HOSTRESPONSEDESCRIPTION,
                  PaymentTransactionTypeId: paymentTrnsId,
                  OrderId: orderId,
                  TransactionDateTime: new Date(),
                  TransactionTypeId: EPaymentTransactionType.Reversal_Refund,
                  VendorId: this.VendorId
                };

                this.toastr.success(`Refund Processed Successfully - $${responseData.RESPONSE.APPROVEDAMOUNT}`, 'Success', this.toasterConfig);
                CCTModel = Object.assign(tempCCModel);
                this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
                });
              } else {
                let CCTModel;
                const tempCCModel = {
                  Amount: responseData.RESPONSE && responseData.RESPONSE.APPROVEDAMOUNT ? responseData.RESPONSE.APPROVEDAMOUNT : initiatingVoid['processedCC'].APPROVEDAMOUNT,
                   CreditCardNumber: responseData.RESPONSE.ACCOUNT == undefined ? '0' : responseData.RESPONSE.ACCOUNT,
                  CreditCardType: cTypeId,
                   ResponseCode: responseData.RESPONSE.RESULT,
                   ResponseMessage: responseData.RESPONSE.RESULTMSG ? responseData.RESPONSE.RESULTMSG : 'Declined',
                  PaymentTransactionTypeId: paymentTrnsId,
                  OrderId: orderId,
                  TransactionDateTime: new Date(),
                  TransactionTypeId: EPaymentTransactionType.Declined,
                  VendorId: this.VendorId
                };
                CCTModel = Object.assign(tempCCModel);
                this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => { });
                this.toastr.error(`Failed to Reverse the Payment - $${initiatingVoid['processedCC'].APPROVEDAMOUNT}`, 'Failure', this.toasterConfig);
              }
              if (this.multiPaymentVoidEmv.some(function (ele) { return (ele.isEmvVoidInitiated == false); })) {
                this.callOpenEdgeVoid(paymentTrnsId);
              } else {
                this.loaderIcon = Promise.resolve(false);
                this.showLoaderMesage = false;
                if (!this.voidOnCancelInitiated) {
                  this.isCancel.emit(true);
                }
              }
            }
          }, err => {
            if (this.multiPaymentVoidEmv.some(function (ele) { return (ele.isEmvVoidInitiated == false); })) {
              this.callOpenEdgeVoid(paymentTrnsId);
            } else {
              if (!this.voidOnCancelInitiated) {
                this.isCancel.emit(true);
                this.toastr.error(`Failed to Reverse the Payment - $${initiatingVoid['processedCC'].APPROVEDAMOUNT}`, 'Failure', this.toasterConfig);
                this.multiPaymentVoidEmv.find(t => t.orderId === initiatingVoid.orderId).isEmvVoidInitiated = true;
                this.showLoaderMesage = false;
                this.loaderIcon = Promise.resolve(false);
                this.initiatedVoidCC = false;
              }
            }
          });
          setTimeout(() => {
            if (!isVoidCompleted) {
              rcmRequest.unsubscribe();
              this.openEdgeTransactionStatus(orderId, 0, 'Void');
            }
          }, 10000 * 6 * this.paymentMaxWaitTime);
        }
      });
    }
  }

  voidPaymentTransaction(verifoneRecordId, credentials) {
    
    this.paymentService.GetVerifoneUnSavedDate(verifoneRecordId).subscribe((res: any) => {
      let CCTModel = this.generateIntialCCT(this.CCAmount, 1, 1, 1, null, null, null,res.OrderId);
      this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((cctRes) => {
        if (cctRes.Success) {
          let cctId = cctRes.Id;
          
          this.verifoneRecordId = cctRes.Id;
          const paymentAmount = this.RefundAmount > 0 ? this.RefundAmount : res.Amount;
          const orderId = res.OrderId;
          const DebitOrderId = cctRes.OrderId;
          const OpenEdgeTransType = res.TransactionTypeId;
          if (!credentials.RCMURL) {
            this.toastr.warning('Please ask Administrator to configure RCM URL', 'URL required', this.toasterConfig);
            this.showLoaderMesage = false;
            this.loaderIcon = Promise.resolve(false);
          } else {
            let TransactionCardType = res.Card;
            var paymentTransactionTypeId = res.PaymentTransactionTypeId;
            var paymentReturnType = "CREDITVOID";
            
            if (TransactionCardType == 'Credit Card') {
              this.paymentService.GetPaymentStatus(this.credentials, paymentTransactionTypeId, orderId).subscribe((response: any) => {
                if (response) {
                  var paymentStatusJsonResp = JSON.parse(response)
                  var paymentStatus = paymentStatusJsonResp.RESULT.STATE;
                  if(this.RefundAmount > 0 ){
                    paymentStatus = "CLOSED";
                  }
                  if (paymentStatus == "CLOSED")
                    paymentReturnType = "CREDITRETURN";
                  else if (paymentStatus == "APPROVED" || response == "CAPTURED" || response == "IN_PROGRESS")
                    paymentReturnType = "CREDITVOID";
                  // ElseIf g_strResponseCode <> "1" Then
                  //       setupParameters = CreatePostString("CREDIT", orderId, CStr(tdbTotalAmount.Value))
                  // End If
                  
                  if(this.ForceCreditReturn == true){
                    paymentReturnType = "CREDITRETURN";
                  }
                  
                  this.paymentService.GetCNPCreditVoid(this.credentials, paymentTransactionTypeId, paymentAmount, paymentReturnType).subscribe((res: any) => {
                    if (res) {
                      const jsonResp = JSON.parse(res);
                      let cTypeId = jsonResp.RESPONSE && jsonResp.RESPONSE.CARDBRAND ? this.paymentService.openEdgeCardType(jsonResp.RESPONSE.CARDBRAND) : ECreditCards.Visa;
                      if (jsonResp.RESULT.RESPONSECODE == EOpenEdgeResponseCode.Approval) {
                        const receiptText = jsonResp.RESULT.RECEIPTTEXT;
                        const transType = receiptText.match(/Trans Type\s+([^\n]+)/);

                        const entryMethodMatch = receiptText.match(/Entry Method\s+([^\n]+)/);
                        const entryLegendMatch = receiptText.match(/Entry Legend\s+([^\n]+)/);
                        const ACMatch = receiptText.match(/AC\s+([^\n]+)/);
                        const ATCMatch = receiptText.match(/ATC\s+([^\n]+)/);
                        const AIDMatch = receiptText.match(/AID\s+([^\n]+)/);
                        const AIDNAMEMatch = receiptText.match(/AID NAME\s+([^\n]+)/);
                        const TVRMatch = receiptText.match(/TVR\s+([^\n]+)/);
                        const TSIMatch = receiptText.match(/TSI\s+([^\n]+)/);
                        const RespCDMatch = receiptText.match(/Resp CD\s+([^\n]+)/);
                        const TRNREFNoMatch = receiptText.match(/TRN REF #\s+([^\n]+)/);
                        const VALCODEMatch = receiptText.match(/VAL CODE\s+([^\n]+)/);
                        
                        const tempCCModel = {
                          VerifoneId: this.verifoneRecordId,
                          Amount: jsonResp.RESULT.APPROVEDAMOUNT,
                          VendorId: this.VendorId,
                          ProcessStatus: "Completed",
                          CreditCardNumber: jsonResp.RESULT.MASKEDCARDNUMBER,
                          ResponseCode: jsonResp.RESULT.RESPONSECODE,
                          ResponseMessage: jsonResp.RESULT.RESPONSEDESCRIPTION,
                          
                          OrderId: orderId,
                          TransactionDateTime: new Date(),
                          TransactionTypeId: OpenEdgeTransType != '' ? OpenEdgeTransType : 'Credit' ,
                          ReceiptResponse: jsonResp.RESULT.RECEIPTTEXT ? jsonResp.RESULT.RECEIPTTEXT : null,
                          Card: TransactionCardType,
                          CreditCardType : jsonResp.RESULT.CARDBRAND,
                          EntryLegend: entryLegendMatch ? entryLegendMatch[1].trim() : '',
                          EntryMethod: entryMethodMatch ? entryMethodMatch[1].trim() : '',
                          AC: ACMatch ? ACMatch[1].trim() : '',
                          ATC: ATCMatch ? ATCMatch[1].trim() : '',
                          AID: AIDMatch ? AIDMatch[1].trim() : '',
                          AIDNAME: AIDNAMEMatch ? AIDNAMEMatch[1].trim() : '',
                          TVR: TVRMatch ? TVRMatch[1].trim() : '',
                          TSI: TSIMatch ? TSIMatch[1].trim() : '',
                          RespCD: RespCDMatch ? RespCDMatch[1].trim() : '',
                          TRNREFNo: TRNREFNoMatch ? TRNREFNoMatch[1].trim() : '',
                          VALCODE: VALCODEMatch ? VALCODEMatch[1].trim() : '',
                          PaymentTransactionTypeId: jsonResp.RESULT.TRANSACTIONID,
                          PaymentTransactionType: transType != "" && transType != null ? transType[1].trim().toString() : 'VOID',
                        };
                        const CCTModel = Object.assign(tempCCModel);
                        this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
                          if(!res.Success){
                            this.toastr.warning('Verifone table failed', 'Info', this.toasterConfig);
                            this.loaderIcon = Promise.resolve(false);
                          }
                        });
                        this.initiatedVoidCC = false;
                        this.loaderIcon = Promise.resolve(false);
                      } else {
                        const cCModel = {
                          VerifoneId: this.verifoneRecordId,
                          Amount: jsonResp.RESULT.APPROVEDAMOUNT ? jsonResp.RESULT.APPROVEDAMOUNT : paymentAmount,
                          CreditCardNumber: jsonResp.RESULT && jsonResp.RESULT.MASKEDCARDNUMBER ? jsonResp.RESULT.MASKEDCARDNUMBER : 'XXXX',
                          ResponseCode: jsonResp.RESULT.RESPONSECODE ? jsonResp.RESULT.RESPONSECODE : null,
                          ResponseMessage: jsonResp.RESULT.RESPONSEDESCRIPTION ? jsonResp.RESULT.RESPONSEDESCRIPTION : 'Declined',
                          Card: 'Credit Card',
                          CreditCardType : jsonResp.RESULT.CARDBRAND,
                          OfflinePayment: 0,
                          PaymentTransactionTypeId: jsonResp.RESULT.TRANSACTIONID,
                          OrderId: orderId,
                          TransactionDateTime: new Date(),
                          TransactionTypeId: "",
                          VendorId: this.VendorId,
                          ProcessStatus: "Failed",
                          ReceiptResponse: jsonResp.RESULT.RECEIPTTEXT ? jsonResp.RESULT.RECEIPTTEXT : null,

                          
                        };
                        this.paymentService.saveVerifoneUnSaved(Object.assign(cCModel)).subscribe((res: any) => { });
                        if (!this.voidOnCancelInitiated) {
                          this.isCancel.emit(true);
                        }
                        this.showLoaderMesage = false;
                        this.loaderIcon = Promise.resolve(false);
                        this.initiatedVoidCC = false;
                      }
                      // if (!this.multiPaymentVoidEmv.some(function (ele) { return (ele.isEmvVoidInitiated == false); })) {
                      //   this.showLoaderMesage = false;
                      //   if (!this.voidOnCancelInitiated) {
                      //     this.isCancel.emit(true);
                      //   }
                      //   this.initiatedVoidCC = false;
                      //   this.loaderIcon = Promise.resolve(false);
                      // }
                      var obj = {
                        VerifoneId: this.verifoneRecordId,
                        PaymentSuccess: jsonResp.RESULT.RESPONSECODE == EOpenEdgeResponseCode.Approval ? true : false,
                        OrderId:orderId,
                        CardNumber : jsonResp.RESULT.MASKEDCARDNUMBER ? jsonResp.RESULT.MASKEDCARDNUMBER : 'XXXX'
                      }
                      this.toastr.info("Credit card payment reversed successfully", 'Info', this.toasterConfig);
                      this.PaymentResponse.emit(obj);
                    }
                  });
                }
              });
            } else {// Debit return
              this.showLoaderMesage = true;
              let requestParam = "<REQUEST> " + "<XWEBID>" + this.credentials.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + this.credentials.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + this.credentials.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>DEBITRETURN</TRANSACTIONTYPE><AMOUNT>" + paymentAmount + "</AMOUNT><ORDERID>" + DebitOrderId + "</ORDERID><OTK>" + DebitOrderId + "</OTK></REQUEST>";
              this.initiatedVoidCC = true;
              const debitVoidRequest = this.paymentService.getRCMService(this.credentials.RCMURL, requestParam).subscribe((res: any) => {
                
                const resultFromRCM = res;
                const responseData = JSON.parse(resultFromRCM.RcmResponse);
                const TransType = responseData.RESPONSE.RECEIPTTEXT.match(/Trans Type\s+([^\n]+)/);
                let cTypeId = responseData.RESPONSE && responseData.RESPONSE.CARDBRAND ? this.paymentService.openEdgeCardType(responseData.RESPONSE.CARDBRAND) : ECreditCards.Visa;
                if (responseData.RESPONSE.RESULT == 0) { //Success
                  if (!this.voidOnCancelInitiated) {
                    this.isCancel.emit(true);
                  }
                  this.showLoaderMesage = false;
                  this.loaderIcon = Promise.resolve(false);
                  let CCTModel;
                  
                  const tempCCModel = {
                    VerifoneId: this.verifoneRecordId,
                    VendorId: this.VendorId,
                    ProcessStatus: "Completed",
                    Amount: responseData.RESPONSE.APPROVEDAMOUNT,
                    CreditCardNumber: responseData.RESPONSE.ACCOUNT == undefined ? 0 : responseData.RESPONSE.ACCOUNT,
                    ResponseCode: responseData.RESPONSE.HOSTRESPONSECODE,
                    ResponseMessage: responseData.RESPONSE.HOSTRESPONSEDESCRIPTION,
                    Card: TransactionCardType,
                    CreditCardType : responseData.RESPONSE.CARDBRAND,
                    PaymentTransactionTypeId:responseData.RESPONSE.TRANSACTIONID,
                    PaymentTransactionType:  TransType != "" && TransType != null ? TransType[1].trim().toString() : 'Debit Void',
                    OrderId: responseData.RESPONSE.ORDERID,
                    TransactionDateTime: new Date(),
                    TransactionTypeId:  OpenEdgeTransType != '' ? OpenEdgeTransType : 'Return'  ,
                    ReceiptResponse: responseData.RESPONSE.RECEIPTTEXT ? responseData.RESPONSE.RECEIPTTEXT : null
                  };
                  CCTModel = Object.assign(tempCCModel);
                  
                  this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
                    if(!res.Success){
                      this.toastr.warning('Verifone table failed', 'Info', this.toasterConfig);
                      this.loaderIcon = Promise.resolve(false);
                    }
                  });
                  this.initiatedVoidCC = false;

                  var obj = {
                    VerifoneId: this.verifoneRecordId,
                    PaymentSuccess: responseData.RESPONSE.HOSTRESPONSECODE == EOpenEdgeResponseCode.Approval ? true : false,
                    OrderId:responseData.RESPONSE.ORDERID,
                    CardNumber : responseData.RESPONSE.MASKEDCARDNUMBER ? responseData.RESPONSE.MASKEDCARDNUMBER : 'XXXX'
                  }
                  this.toastr.warning(responseData.RESPONSE.HOSTRESPONSEDESCRIPTION, 'Info', this.toasterConfig);
                  this.PaymentResponse.emit(obj);
                  this.loaderIcon = Promise.resolve(false);
                } else {
                  let CCTModel;
                  const tempCCModel = {
                    VerifoneId: this.verifoneRecordId,
                    Amount: responseData.RESPONSE.APPROVEDAMOUNT ? responseData.RESPONSE.APPROVEDAMOUNT : paymentAmount,
                    CreditCardNumber: responseData.RESPONSE.ACCOUNT == undefined ? '0' : responseData.RESPONSE.ACCOUNT,
                    ResponseCode: responseData.RESPONSE.RESULT,
                    ResponseMessage: responseData.RESPONSE.RESULTMSG ? responseData.RESPONSE.RESULTMSG : 'Declined',
                    CreditCardType: responseData.RESPONSE.CARDBRAND,
                    PaymentTransactionTypeId: responseData.RESPONSE.TRANSACTIONID,
                    OrderId: orderId,
                    VendorId: this.VendorId,
                    ProcessStatus: "Failed",
                    TransactionDateTime: new Date(),
                    TransactionTypeId: EPaymentTransactionType.Declined,
                    ReceiptResponse: responseData.RESPONSE.RECEIPTTEXT ? responseData.RESPONSE.RECEIPTTEXT : null
                  };
                  CCTModel = Object.assign(tempCCModel);
                  this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => { });
                  if (!this.voidOnCancelInitiated) {
                    this.isCancel.emit(true);
                  }
                  this.toastr.error('Failed to Reverse the Payment!', 'Failure', this.toasterConfig);
                  this.showLoaderMesage = false;
                  this.loaderIcon = Promise.resolve(false);
                  this.initiatedVoidCC = false;

                  var obj = {
                    VerifoneId: this.verifoneRecordId,
                    PaymentSuccess: responseData.RESPONSE.HOSTRESPONSECODE == EOpenEdgeResponseCode.Approval ? true : false,
                    OrderId:responseData.RESPONSE.ORDERID,
                    CardNumber : responseData.RESPONSE.MASKEDCARDNUMBER ? responseData.RESPONSE.MASKEDCARDNUMBER : 'XXXX'
                  }
                  this.toastr.warning(responseData.RESPONSE.HOSTRESPONSEDESCRIPTION, 'Info', this.toasterConfig);
                  this.PaymentResponse.emit(obj);
                  this.loaderIcon = Promise.resolve(false);
                }
                this.showLoaderMesage = false;
              }, err => {
                if (!this.multiPaymentVoidEmv.some(function (ele) { return (ele.isEmvVoidInitiated == false); })) {
                  this.showLoaderMesage = false;
                  if (!this.voidOnCancelInitiated) {
                    this.isCancel.emit(true);
                  }
                  this.initiatedVoidCC = false;
                  this.loaderIcon = Promise.resolve(false);
                }
              });
            }
          }
        }
      });
    });
  }

  getRCMStatus(orderId, transactinSuccess) {
    this.paymentService.getRCMServiceStatus(null, orderId, false).subscribe((sessionRes: any) => {
      if (sessionRes) {
        if (sessionRes.SessionState == EOpenEdgeSessionStates.PromptingForCardRemoval) {
          this.toastr.warning('Please Remove Your Card From EMV Device', 'Alert', this.toasterConfig);
        }
        this.cancelRcmRequest(sessionRes.SessionId, transactinSuccess);
      }
    });
  }

  cancelRcmRequest(sessionId, isTransactionSuccess) {
    this.paymentService.getRCMServiceStatus(sessionId, 0, true).subscribe((respose: any) => {
      if (!respose.IsSuccessful && !isTransactionSuccess) {
        this.toastr.warning('Please click on Cancel Button in the EMV Device, if it is not in Home Screen. Ignore if already in Home Screen', 'Alert', this.toasterConfig);
      }
    });
  }

  getExpiryMonthValue(expiryMonth) {
    let tempMonth = 263;
    if (expiryMonth) {
      if (expiryMonth > 0 && expiryMonth < 13) {
        expiryMonth = tempMonth + expiryMonth;
      } else if (expiryMonth > 263 && expiryMonth < 276) {
        expiryMonth = expiryMonth;
      } else {
        expiryMonth = 264;
      }
    } else {
      expiryMonth = 264;
    }
    return expiryMonth;
  }

  saveInVerifoneUnsaved(amount, payId) {
    this.paymentFailReason = '';
    let CCTModel = this.generateIntialCCT(this.CCAmount, 1, 1, 1, null, null, null, null);
    this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((cctRes) => {
     if (cctRes.Success) {
        let cctId = cctRes.Id;
        this.verifoneRecordId = cctRes.Id;
        this.paymentTable.find(o => o.payId == payId).orderId = cctRes.OrderId;
        this.isSaveInCCTResponse = true;
        if (!this.keyedInTransaction) {          
            this.callRCM(amount, payId, cctRes.OrderId); // Transaction type(SALE) is decided by user when RCM device popsup a option to choose
         
        } else if (this.keyedInTransaction) {
            this.tempCNPAmount = amount;
            this.tempCNPPayId = payId;
            this.callCNP(amount, 'CREDITSALE', payId); // CNP is always CREDITSALE, Debit card is N/A
        }
      } else {
        this.loaderIcon = Promise.resolve(false);
        this.paymentStarted = false;
        this.toastr.error("We couldn't create CCT, Please try again!", 'Failure', this.toasterConfig);
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
      this.paymentStarted = false;
      this.toastr.error(' Something went wrong!', 'Failure', this.toasterConfig);
    });
  }

  initiatePaymentRequest() {
    this.paymentStarted = true;
    const amount = this.CCAmount;
    const payId = 1;
    this.currentPayId = payId;
    this.saveInVerifoneUnsaved(amount, payId);
    this.loaderIcon = Promise.resolve(true);
  }

  getHiddenInput() {
    this.hstPayBtnMsg = 'Cancel';
    let iframeSrc = this.iframe.nativeElement.src.split('/');
    let tempId = iframeSrc.pop();
    if (!this.credentials.QueryPaymentStatusURL) {
      this.toastr.warning('Please ask Administrator to configure CNP URL', 'URL required', this.toasterConfig);
    } else {
      this.paymentInProgress = true;
      if (this.sessionId === tempId) {
       // this.showLoaderMesage = true;
        this.loaderIcon = Promise.resolve(true);
        this.hstPayBtnMsg = 'OK';
        this.sessionId = tempId;
        //let paymentTrnsId = this.paymentService.GetPaymentTransactionTypeId(this.processPayment.Type);
        const hostedpageRequest = this.paymentService.getCNPResponse(this.credentials, this.paymentTable.find(o => o.payId == this.currentPayId).uniqueTransId).subscribe((res: any) => {
          const jsonResp = JSON.parse(res);
          if (jsonResp) {
            this.showIFrame = false;
            this.showPage = !this.showIFrame;
           // this.prePayStatus = 'Payment';
            if (jsonResp.RESULT.RESPONSECODE == EOpenEdgeResponseCode.Approval) {
              this.loaderIcon = Promise.resolve(false);
              this.paymentSuccessFul = true;
              this.payStatus = 'Success';
              //this.paymentTable.find(o => o.payId == this.tempCNPPayId).payFailed = false;
             // this.disableNextButton();
              let amount = parseFloat(jsonResp.RESULT.APPROVEDAMOUNT).toFixed(2);
              this.paymentTable.find(o => o.payId == this.currentPayId)['processedCNPCC'] = jsonResp.RESULT;
              let CCTModel;
              const receiptText = jsonResp.RESULT.RECEIPTTEXT;
              const entryMethodMatch = receiptText.match(/Entry Method\s+([^\n]+)/);
              const tempCCModel = {
                VerifoneId: this.verifoneRecordId,
                Amount: parseFloat(amount),
                CreditCardNumber: jsonResp.RESULT.MASKEDCARDNUMBER,
                ResponseCode: jsonResp.RESULT.ORIGINALRESPONSECODE,
                ResponseMessage: jsonResp.RESULT.ORIGINALRESPONSEDESCRIPTION,
                Card: this.keyedInTransaction ? "Credit Card": "",
                // PaymentTransactionTypeId: 1,
                TransactionDateTime: new Date(),
                TransactionTypeId: jsonResp.RESULT.ORIGINALTRANSACTIONTYPE,
                OrderId: jsonResp.RESULT.ORDERID,
                VendorId: this.VendorId,
                ProcessStatus: "Completed",
                ApprovalCode: jsonResp.RESULT.APPROVALCODE,
                PaymentTransactionTypeId:jsonResp.RESULT.TRANSACTIONID,
                PaymentTransactionType: "Payment Purchase",
                CreditCardType: jsonResp.RESULT.CARDBRAND,
                EntryLegend:"CNP",
                EntryMethod: entryMethodMatch ? entryMethodMatch[1].trim() : '',
                ReceiptResponse: jsonResp.RESULT.RECEIPTTEXT
              };

              CCTModel = Object.assign(tempCCModel);
              this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
              });
              this.MASKEDCARDNUMBER = jsonResp.RESULT.MASKEDCARDNUMBER
              this.gotoPaymentSuccess(this.popUpPayment, amount);
            } else if (jsonResp.RESULT.STATE == 'IN_PROGRESS' || (jsonResp.RESULT && jsonResp.RESULT.RESPONSECODE == EOpenEdgeResponseCode.TransactionDoesNotExist && jsonResp.RESULT.RESPONSEDESCRIPTION == 'Invalid Reference Error: Transaction does not exist')) {
              if (hostedpageRequest != null) {
                hostedpageRequest.unsubscribe();
              }
              this.paymentSuccessFul = false;
              this.payStatus = 'Failed';
             // this.gotoCNPRetryLogic(this.paymentTable.find(o => o.payId == this.currentPayId).uniqueTransId, this.paymentTable.find(o => o.payId == this.currentPayId).orderId, 'Sale', paymentTrnsId);
            } else {
              this.paymentSuccessFul = false;
              this.paymentProcessing = false;
              this.payStatus = 'Failed';
              this.paymentTable.find(o => o.payId == this.currentPayId).payFailed = true;
              jsonResp['PaymentModeId'] = EPaymentModes.HostedPage;
              //this.saveCCTransactionFailureCase(jsonResp, jsonResp.RESULT && jsonResp.RESULT.RESPONSEDESCRIPTION ? jsonResp.RESULT.RESPONSEDESCRIPTION : 'Declined!', 'Sale', paymentTrnsId, this.paymentTable.find(o => o.payId == this.currentPayId).orderId);
              if (jsonResp.RESULT && jsonResp.RESULT.RESPONSECODE && jsonResp.RESULT.RESPONSECODE == EOpenEdgeResponseCode.ImproperFieldData) {
                if (jsonResp.RESULT.RESPONSEDESCRIPTION) {
                  jsonResp.RESULT.RESPONSEDESCRIPTION = jsonResp.RESULT.RESPONSEDESCRIPTION + '. ' + 'Customer Name should be less than 50 characters!'
                  this.toastr.warning(jsonResp.RESULT.RESPONSEDESCRIPTION, 'Warning', this.toasterConfig);
                }
              }
              let CCTModel;
              const tempCCModel = {
                VerifoneId: this.verifoneRecordId,
                CreditCardNumber: jsonResp.RESULT.MASKEDCARDNUMBER,
                CardType: jsonResp.RESULT.CARDTYPE,
                ResponseCode: jsonResp.RESULT.ORIGINALRESPONSECODE,
                ResponseMessage: jsonResp.RESULT.ORIGINALRESPONSEDESCRIPTION,
                Card: this.keyedInTransaction ? "Credit Card": "",
                TransactionDateTime: new Date(),
                OrderId: jsonResp.RESULT.ORDERID,
                VendorId: this.VendorId,
                ProcessStatus: "Failed",
                PaymentTransactionTypeId:jsonResp.RESULT.TRANSACTIONID,
                CreditCardType: jsonResp.RESULT.CARDTYPE
              };
              this.paymentFailReason = jsonResp.RESULT.ORIGINALRESPONSEDESCRIPTION;

              CCTModel = Object.assign(tempCCModel);
              this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
              });
              this.MASKEDCARDNUMBER = jsonResp.RESULT.MASKEDCARDNUMBER
              this.gotoPaymentSuccess(this.popUpPayment, 0);
              this.loaderIcon = Promise.resolve(false);
            }
          }
        }, err => {
          this.loaderIcon = Promise.resolve(false);
          this.tempCNPRes = {
            PaymentModeId: EPaymentModes.HostedPage
          }
          this.paymentFailReason = 'Declined!';
          this.showIFrame = false;
          this.showPage = !this.showIFrame;
          this.payStatus = 'Failed';
          this.paymentStarted = false;
          this.paymentTable.find(o => o.payId == this.currentPayId).payFailed = true;
          this.toastr.error('Payment Failed', 'Failure');
          this.gotoPaymentSuccess(this.popUpPayment, 0);
          let CCTModel;
          const tempCCModel = {
            VerifoneId: this.verifoneRecordId,
            Card: this.keyedInTransaction ? "Credit Card": "",
            TransactionDateTime: new Date(),
            OrderId: this.paymentTable.find(o => o.payId == this.currentPayId).uniqueTransId,
            VendorId: this.VendorId,
            ProcessStatus: "Failed"
          };
          CCTModel = Object.assign(tempCCModel);
              this.paymentService.saveVerifoneUnSaved(CCTModel).subscribe((res: any) => {
              });
        });
      } else {
        this.sessionId = tempId;
      }
    }
  }

  gotoPaymentSuccess(template: TemplateRef<any>, amount) {
    this.paymentStarted = true;
    this.tillNowPaid += parseFloat(amount);
    if (this.paymentSuccessFul) {
      this.paymentTable.find(o => o.payId === this.currentPayId).payComplete = true;
    }
    ///////////////////////////////////////////////////////////
    // if (!this.paymentSuccessFul) {
    if(!this.IsMR){
    this.payModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
    }
    if (this.paymentSuccessFul) {
      var obj = {
        VerifoneId: this.verifoneRecordId,
        PaymentSuccess: this.paymentSuccessFul,
        OrderId: this.paymentTable.find(o => o.payId == this.currentPayId).uniqueTransId,
        CardNumber : this.MASKEDCARDNUMBER
      }
      this.PaymentResponse.emit(obj);
    }
    else{
      var objFailure = {
        VerifoneId: this.verifoneRecordId,
        PaymentSuccess: this.paymentSuccessFul,
        OrderId: this.paymentTable.find(o => o.payId == this.currentPayId).uniqueTransId
      }
      this.PaymentResponse.emit(objFailure);
    }
  }

  afterpaymentSuccess(){
    this.payModalRef.hide();
    // if(this.isQDR){
    //   this.sharedDataService.paymentSharedData = {};
    //   const url = 'ops/qdr';
    //   this.router.navigate([url]);
    // }else{
    // //    const url = 'ops/vendors/details/' + this.VendorId + '/3';
    // //  this.router.navigate([url]);
    // }
  }

  // gotoCNPRetryLogic(uniqTransId, orderId, transactionType, paymentTrnsId) {
  //   this.paymentService.GetCreditCardTransactionById(orderId).subscribe((cctResponse: any) => {
  //     let res = JSON.parse(cctResponse);
  //     if (res.Reason == "Attempting to Process") {
  //       const source = Observable.interval(this.paymentGetCCTStatusInterval * 1000);
  //       this.subOpenEdgeHostedpage = source.subscribe(val => this.cnpTransactionStatus(uniqTransId, val, orderId, transactionType, paymentTrnsId));
  //     } else {
  //       this.paymentSuccessFul = false;
  //       this.paymentProcessing = false;
  //       this.retryTransactionStatusLoader = false;
  //       this.payStatus = 'Failed';
  //       this.isAnyPaymentProcessed();
  //       this.paymentFailReason = 'Payment Status not received. Please Contact Administrator to check in Gateway Portal if Payment is Approved. If Yes, key in the Payment as Manual Authorization';
  //       this.gotoPaymentSuccess(this.popUpPayment, 0);
  //       this.loaderIcon = Promise.resolve(false);
  //     }
  //   }, err => {
  //     this.paymentTable.find(o => o.payId == this.currentPayId).payFailed = true;
  //     this.loaderIcon = Promise.resolve(false);
  //     this.tempCNPRes['PaymentModeId'] = EPaymentModes.HostedPage;
  //     this.saveCCTransactionFailureCase(this.tempCNPRes, 'Declined!', transactionType, paymentTrnsId, orderId);
  //   });
  // }

  callTransactionComplete() {
  //  this.showLoaderMesage = true;
    this.loaderIcon = Promise.resolve(true);
    this.transactionInitiated = true;
  }

  callCNP(amount, transType, payId) {
    
    if (!this.credentials.CardNotPresentURL) {
      this.paymentStarted = false;
      this.toastr.warning('Please ask Administrator to configure CNP URL', 'URL required', this.toasterConfig);
      this.loaderIcon = Promise.resolve(false);
    } else {
      let rqsturl = 'https://ee.test.paygateway.com/HostPayService/v1/hostpay/transactions/';
      if (this.isAutoPopulateBillingInfo && this.vendorInfo) {
        this.setCNPvendorInfo(amount, transType, payId);
      } else {
        const tempOrderId = this.paymentTable.find(o => o.payId == payId).orderId;
        this.paymentTable.find(o => o.payId == payId).uniqueTransId = tempOrderId;
        this.requestParam =
          "<REQUEST>" +
          "<XWEBID>" + this.credentials.XWEBID + "</XWEBID>" +
          "<XWEBTERMINALID>" + this.credentials.XWEBTERMINALID + "</XWEBTERMINALID>" +
          "<XWEBAUTHKEY>" + this.credentials.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
          "<TRANSACTIONTYPE>" + transType + "</TRANSACTIONTYPE>" +
          "<AMOUNT>" + amount + "</AMOUNT>" +
          "<CLERK></CLERK>" +
          "<ORDERID>" + tempOrderId + "</ORDERID>" +
          "<ECI>" + EEdgeExpressECIType.Moto + "</ECI>" +
          "<HOSTPAYSETTING>" +
          "<CARDBRAND>" + this.facilityCreditCards + "</CARDBRAND>" +
          "<DISABLEFRAMING>FALSE</DISABLEFRAMING>" +
          "<RECEIPTFORMAT>TEXT</RECEIPTFORMAT>" +
          "<CUSTOMIZATION>" +
          "<PAGE> " +
          this.openEdgeBillingFields +
          "</PAGE>" +
          "<BRANDING>" +
          "<BODY>" +
          "<FONT-FAMILY> LATO,SANS-SERIF;</FONT-FAMILY>" +
          "<FONT-SIZE>14px</FONT-SIZE>" +
          "</BODY>" +
          "<BTN>" +
          "<BACKGROUND-COLOR>#3757C5;</BACKGROUND-COLOR>" +
          "<FONT-SIZE>14PX;</FONT-SIZE>" +
          "</BTN>" +
          "<SPECIALCSS>" +
          "<SECTION-HEADER-FONT-SIZE>18px</SECTION-HEADER-FONT-SIZE>" +
          "<COLOR>#3757C5;</COLOR>" +
          "</SPECIALCSS>" +
          "</BRANDING>" +
          "</CUSTOMIZATION>" +
          "<POSDEVICE>" +
          "<TYPE>KEYED</TYPE>" +
          "</POSDEVICE>" +
          "</HOSTPAYSETTING>" +
          "<BILLINGPOSTALCODE> <REQUIRED>TRUE</REQUIRED> </BILLINGPOSTALCODE>" +
          "</REQUEST>";
      }
      this.paymentService.getCNPService(this.credentials.CardNotPresentURL, this.requestParam).subscribe((res: any) => {
        this.loaderIcon = Promise.resolve(false);
        const resultResponse = JSON.parse(res);
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(resultResponse.RESULT.PAYPAGEURL);
        this.showIFrame = true;
        this.showPage = !this.showIFrame;
        this.loaderIcon = Promise.resolve(false);
      }, err => {
        this.paymentStarted = false;
        this.toastr.warning('Please ask Administrator to configure CNP URL', 'URL required', this.toasterConfig);
        this.loaderIcon = Promise.resolve(false);
      }
      );
    }
  }

  getOpenEdgeTransactionStatus(orderId, payId, count, transactionType, paymentTrnsId) {
    count = count + 1; //count start from 0
    let maxTime = (this.paymentMaxWaitTime * 60) / this.paymentGetCCTStatusInterval;
    if (count > maxTime) {
      this.cancelRcmRequest(this.tempRcmRes.rcmSessionId, false);
      this.retryTransactionStatusLoader = false;
      this.paymentFailReason = "Payment Status not received. Please Contact Administrator to check in Gateway Portal if Payment is Approved. If Yes, key in the Payment as Manual Authorization";
      if (this.openEdgeSub) {
        this.openEdgeSub.unsubscribe();
        this.openEdgeSub = null;
      }
      this.paymentTable.find(o => o.payId === payId).payFailed = true;
      this.saveCCTransactionFailureCase(this.tempRcmRes.rcmResponse, this.paymentFailReason, transactionType, paymentTrnsId, orderId);
    } else {
      this.initiatingOpenEdgeQueryPaymentRequest(orderId, transactionType, paymentTrnsId, payId, false)
    }
  }

  setOpenEdgeMandatory() {
    this.openEdgeBillingFields = '';
    var mandatoryField = '';
    mandatoryField = `<BILLINGFIRSTNAME> <REQUIRED>FALSE</REQUIRED> </BILLINGFIRSTNAME>`;
    mandatoryField += `<BILLINGLASTNAME> <REQUIRED>FALSE</REQUIRED> </BILLINGLASTNAME>`;
    mandatoryField += `<BILLINGADDRESSONE> <REQUIRED>FALSE</REQUIRED> </BILLINGADDRESSONE>`;
    mandatoryField += `<BILLINGADDRESSTWO> <REQUIRED>FALSE</REQUIRED><VISIBLE>FALSE</VISIBLE></BILLINGADDRESSTWO>`;
    mandatoryField += `<BILLINGPOSTALCODE> <REQUIRED>TRUE</REQUIRED> </BILLINGPOSTALCODE>`;
    mandatoryField += `<BILLINGSTATEORPROVINCE> <REQUIRED>FALSE</REQUIRED> </BILLINGSTATEORPROVINCE>`;
    mandatoryField += `<BILLINGCITY> <REQUIRED>FALSE</REQUIRED> </BILLINGCITY>`;
    mandatoryField += `<BILLINGCOUNTRYCODE> <REQUIRED>FALSE</REQUIRED> </BILLINGCOUNTRYCODE>`;
    mandatoryField += `<BILLINGCUSTOMERTITLE> <REQUIRED>FALSE</REQUIRED> </BILLINGCUSTOMERTITLE>`;
    mandatoryField += `<BILLINGMIDDLENAME> <REQUIRED>FALSE</REQUIRED> </BILLINGMIDDLENAME>`;
    mandatoryField += `<BILLINGCOMPANY> <REQUIRED>FALSE</REQUIRED> <VISIBLE>FALSE</VISIBLE></BILLINGCOMPANY>`;

    this.openEdgeBillingFields = mandatoryField;
  }
  
  setCNPvendorInfo(amount, transType, payId) {
   this.vendorInfo.Address =  this.vendorInfo.Address &&  this.vendorInfo.Address.length > 50 ?
    this.vendorInfo.Address.substring(0, 50) : this.vendorInfo.Address;
    let firstName = this.paymentService.replaceXMLEntity(this.vendorInfo.FirstName);
    let middleName = this.paymentService.replaceXMLEntity(this.vendorInfo.MiddleName);
    let lastName = this.paymentService.replaceXMLEntity(this.vendorInfo.LastName);
    let address = this.paymentService.replaceXMLEntity(this.vendorInfo.Address);
    let companyName = this.paymentService.replaceXMLEntity(this.vendorInfo.CompanyName);
    let city = this.paymentService.replaceXMLEntity(this.vendorInfo.City);

    const tempOrderId = this.paymentTable.find(o => o.payId == payId).orderId;
    this.paymentTable.find(o => o.payId == payId).uniqueTransId = tempOrderId;
    this.requestParam = "<REQUEST>" +
      "<XWEBID>" + this.credentials.XWEBID + "</XWEBID>" +
      "<XWEBTERMINALID>" + this.credentials.XWEBTERMINALID + "</XWEBTERMINALID>" +
      "<XWEBAUTHKEY>" + this.credentials.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
      "<TRANSACTIONTYPE>" + transType + "</TRANSACTIONTYPE>" +
      "<AMOUNT>" + amount + "</AMOUNT>" +
      "<BILLINGFIRSTNAME>" + firstName + "</BILLINGFIRSTNAME>" +
      "<BILLINGMIDDLENAME>" + middleName + "</BILLINGMIDDLENAME>" +
      "<BILLINGLASTNAME>" + lastName + "</BILLINGLASTNAME>" +
      "<BILLINGADDRESS1>" + address + "</BILLINGADDRESS1>" +
      "<BILLINGCOMPANY>" + companyName + "</BILLINGCOMPANY>" +
      "<BILLINGCITY>" + city + "</BILLINGCITY>" +
      "<BILLINGCOUNTRY>" + this.vendorInfo.CountryCode + "</BILLINGCOUNTRY>" +
      "<BILLINGSTATE>" + this.vendorInfo.StateCode??"" + "</BILLINGSTATE>" +
      "<BILLINGPOSTALCODE>" + this.vendorInfo.PostalCode?? "" + "</BILLINGPOSTALCODE>" +
      "<CLERK></CLERK>" +
      "<ORDERID>" + tempOrderId + "</ORDERID>" +
      "<ECI>" + EEdgeExpressECIType.Moto + "</ECI>" +
      "<HOSTPAYSETTING>" +
      "<CARDBRAND>" + this.facilityCreditCards + "</CARDBRAND>" +
      "<DISABLEFRAMING>FALSE</DISABLEFRAMING>" +
      "<CUSTOMIZATION>" +
      "<PAGE> " +
          this.openEdgeBillingFields +
      "</PAGE>" +
      "<BRANDING>" +
      "<BODY>" +
      "<FONT-FAMILY> LATO,SANS-SERIF;</FONT-FAMILY>" +
      "<FONT-SIZE>14px</FONT-SIZE>" +
      "</BODY>" +
      "<BTN>" +
      "<BACKGROUND-COLOR>#3757C5;</BACKGROUND-COLOR>" +
      "<FONT-SIZE>14PX;</FONT-SIZE>" +
      "</BTN>" +
      "<SPECIALCSS>" +
      "<SECTION-HEADER-FONT-SIZE>18px</SECTION-HEADER-FONT-SIZE>" +
      "<COLOR>#3757C5;</COLOR>" +
      "</SPECIALCSS>" +
      "</BRANDING>" +
      "</CUSTOMIZATION>" +
      "<POSDEVICE>" +
      "<TYPE>KEYED</TYPE>" +
      "</POSDEVICE>" +
      "</HOSTPAYSETTING>" +
      "</REQUEST>";
  } 

  onhstPayBtn(val) {
    if (val == 'Cancel') {
      this.ClosePaymentPage.emit()
    }
  }
}
