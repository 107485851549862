export enum AllowNewBookings {
    'All Vendors' = 1,
    'New Vendors' = 2,
    'Existing Vendors' = 3
}

export enum RentalSettings {
    'MaxBookingMonths' = 24,
    'MaxBookingWeeks' = 52,
    'MaxBookingDaily' = 120,
    'SelectedDay' = 9
}

export enum RentalTypes {
    'Daily' = 1,
    'Monthly' = 2,
    'Weekly' = 3,
    'CombinedMonthly' = 4,
    'CombinedWeekly' = 5,
    'QuickDaily' = 6,
    'Event' = 7
}

export enum RentalTypesDescription {
    'Daily' = 'Daily',
    'Monthly' = 'Monthly',
    'Weekly' = 'Weekly',
    'CombinedMonthly' = 'Combined Monthly',
    'CombinedWeekly' = 'Combined Weekly',
    'QuickDaily' = 'Quick Daily',
    'Event' = 'Event'
}

export enum BoothTerm {
    'Space' = 1,
    'Booth' = 0
}

export enum EPresetValue {
    "Daily" = 1,
    "WeekToDate" = 2,
    "MonthToDate" = 3,
    "YearToDate" = 4,
    "Custom" = 5,
}

export enum EOperationalReports {
    "OutstandingBalanceReport" = 38,
    "BoothPriceListing" = 51,
    "OtherItemPriceListing" = 39,
    "TransactionJournal" = 43,
    "AvailableBooths" = 42,
    "VendorReservedBooths" = 40,
    "VendorBalancesReport" = 41,
    "FinancialAudit" = 44,
    "YTDPaymentsReport" = 84,
    "RevenueByBooths" = 85,
    "VendorBoothsReport"=83,
    "ProformaJournal"=100
}

export enum EManagementReports {

    "ManagementSummaryReport" = 52,
    "FutureRentals" = 50
}

export enum EVendorReports {

    "VendorCheckIn" = 46,
    "VendorDepositBalance" = 47,
    "AbsenteeVendors" = 45,
    "VendorBookingReport" = 48,
    "VendorDiscountPlan" = 104
}

export enum EEdgeExpressECIType {
    "Moto" = 1,
    "Recurring" = 2
}

export enum EOpenEdgeResponseCode {
    "Approval" = "000",
    "ZeroDollarAuthApproval" = "032",
    "ImproperFieldData" = 811,
    "TransactionDoesNotExist" = 814,
    "TransactionAlreadyVoided" = 815
}

export enum EPaymentModes {
    "Cash" = 1,
    "Check" = 2,
    "CreditCard" = 3,
    "DirectDebitEFT" = 4,
    "HostedPage" = 5,
    "ACH" = 6,
    "Gift_Card" = 7,
    "ManualAuthorization" = 8,
    "CardOnfile" = 9,
    "ACHOnFile" = 10,
    "ACHManualAuthorization" = 11
}

export enum EPaymentTransactionType {
    "Processed" = 357,
    "Declined" = 358,
    "Reversal_Refund" = 359
}

export enum ECreditCards {
    "American_Express" = "1",
    "Visa" = "2",
    "Master_Card" = "3",
    "Discover" = "4",
    "Diners" = "5",
    "JCB" = "6"
}

export enum EPaymentGateway {
    "OpenEdgeExpress" = 209,
}

export enum EEdgeExpressResultCode {
    "Success" = 0,
    "Canceled" = 3,
    "Fail" = 4,
    "NoDeviceConfigured" = 5,
    "DeviceNotConfigured" = 6,
    "DeviceInitError" = 7,
    "Declined" = 12,
    "Error_Reading_Chip" = 15
}

export enum EOpenEdgeSessionStates {
    "NotFound" = 0,
    "Initializing" = 1,
    "Processing" = 2,
    "Finalizing" = 3,
    "Canceling" = 4,
    "Completed" = 5,
    "PromptingForCard" = 6,
    "PromptingForCardRemoval" = 7,
    "ChipErrorPromptingForCardRemoval" = 8

}

export enum MarketInfoAndSettingsFunctionID {
    "MarketInfo" = 1,
    "Setup" = 2,
    "VendorSettings" = 3,
    "PrinterSettings" = 4,
    "LayoutParts" = 14,
    "BoothTypes" = 15,
    "Layout" = 16,
    "RevenueCategory" = 17,
    "ProductCategory" = 18,
    "Product" = 19,
    "OtherCharges" = 20,
    "OnlinePaymentSettings" = 45,
    "EmailSettings" = 46,
    "MarketCreditCards" = 50,
    "DiscountPlan" = 55,
    "CustomModificationSetting" = 56,
    "OnlineWebRentalSettings" = 57,
    "News"=59
}

export enum MaintainanceFunctionID {
    "ProductCategory" = 5,
    "Product" = 6,
    "RevenueCategory" = 7,
    "OtherCharges" = 8,
    "VendorTypes" = 9,
    "EmailTemplates" = 10
}

export enum SecurityFunctionID {
    "UserGroup" = 11,
    "User" = 12
}

export enum AccountingFunctionID {
    "CreditCards" = 13
}

export enum DashboardFunctionID {
    "Dashboard" = 21
}

export enum OperationsFunctionID {
    "Vendor" = 22,
    "AutomaticRentRenewal" = 23,
    "QuickDailyRental" = 24,
    "VoidTransactions" = 25,
    "BoothHistory" = 26,
    "CheckIn" = 27,
    "MakeAvailable" = 28,
    "DepositSlip" = 29,
    "ViewLayout" = 47,
    "DeleteRental" = 48,
    "MarkAbsentee" = 49,
    "ProformaInvoice" = 54,
    "GlobalClosOut" = 60,
}

export enum DiscountType {
    "FixedAmount" = "Fixed Amount",
    "Percentage" = "Percentage"
}